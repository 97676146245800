import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/nulogy.design/nulogy.design/src/components/MarkdownRenderer.js";
import { Tooltip, Button } from "@nulogy/components";
import { config } from "../../playgrounds/tooltip";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Playground = makeShortcode("Playground");
const Example = makeShortcode("Example");
const PropsTable = makeShortcode("PropsTable");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Tooltip`}</h1>
    <p>{`User-triggered messages that provide additional information about something on a page. Tooltips are accessed by hovering with a mouse or tapping on a touch screen.`}</p>
    <Playground {...config} mdxType="Playground" />
    <h2>{`Use when`}</h2>
    <p>{`There is additional information that could be helpful to a user`}</p>
    <h2>{`Don't use when`}</h2>
    <p>{`The information contained is vital to completing a task`}</p>
    <h2>{`Variations`}</h2>
    <h3>{`With non-text content`}</h3>
    <Example componentName="tooltip" scope={{
      Tooltip,
      Button
    }} example="CustomContent" mdxType="Example" />
    <h2>{`Usage Guidelines`}</h2>
    <ul>
      <li parentName="ul">{`The Tooltip component is only supported for Buttons and Links`}</li>
      <li parentName="ul">{`Only add a Tooltip to other elements that are focusable`}</li>
    </ul>
    <h2>{`Props`}</h2>
    <PropsTable propsConfig={config.props} mdxType="PropsTable" />
    <h2>{`Related Components`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/iconic-button"
        }}>{`IconicButton`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/truncated-text"
        }}>{`TruncatedText`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      